import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Splash from "../../components/splash"
import styled from "@emotion/styled"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { callToActionStyle } from "../../styles"
import { CAPABILITIES } from "../../config"

const CapabilitiesPage = ({ splashImage, images }) => (
  <Layout>
    <SEO title="Capabilities" />
    <Splash title="Capabilities" subtitle="">
      <Img style={{ minHeight: "150px" }} fluid={splashImage} />
    </Splash>
    <BlockContainer>
      {Object.keys(CAPABILITIES)
        .slice(0, 4)
        .map((b, i) => renderBlock(CAPABILITIES[b], images[i]))}
    </BlockContainer>
  </Layout>
)

const BlockContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  grid-gap: 0% 10%;
  grid-template-rows: auto;
  margin-bottom: 5rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
    text-align: center;
  }
`

const Block = styled.div`
  color: ${props => props.theme.colors.primary};
  margin: 2rem 0;
  h4 {
    font: 400 ${props => props.theme.fontSize.subheader}
      ${props => props.theme.fonts.serif};
  }

  h5 {
    font: 400 32px ${props => props.theme.fonts.serif};
  }

  p {
    font-size: ${props => props.theme.fontSize.paragraph};
    line-height: 24px;
    margin: 1rem 0;
  }
  .gatsby-image-wrapper {
    height: 100%;
    max-height: 300px;
  }
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: block;
    text-align: center;
    .gatsby-image-wrapper {
      max-height: 150px;
    }
  }
`

const renderBlock = (block, img) => {
  return (
    <Block>
      <h4>{block.title}</h4>
      <Img
        imgStyle={{ objectPosition: "bottom" }}
        style={{ }}
        fluid={img}
      />
      {/* <h5>{block.subtitle}</h5> */}
      <p>{block.text}</p>
      <Link css={theme => callToActionStyle(theme)} to={block.href}>
        Learn More
      </Link>
    </Block>
  )
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "banners/capabilities.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      devImage: file(relativePath: { eq: "63/cube_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      acquisitionImage: file(relativePath: { eq: "1500/front_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      partnershipImage: file(relativePath: { eq: "rockville/1.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      assetImage: file(relativePath: { eq: "2dag/outside_address_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const devImage = data.devImage.childImageSharp.fluid
  const acquisitionImage = data.acquisitionImage.childImageSharp.fluid
  const splashImage = data.splashImage.childImageSharp.fluid
  const assetImage = data.assetImage.childImageSharp.fluid
  const partnershipImage = data.partnershipImage.childImageSharp.fluid
  const images = [devImage, acquisitionImage, assetImage, partnershipImage]
  return (
    <CapabilitiesPage {...props} splashImage={splashImage} images={images} />
  )
}
